import { IComponentsRegistrar } from '@wix/thunderbolt-components-loader'
import { named, optional, withDependencies } from '@wix/thunderbolt-ioc'
import { SiteFeatureConfigSymbol } from '@wix/thunderbolt-symbols'
import { name, OOISsrManagerSymbol, ReactLoaderForOOISymbol } from './symbols'
import { OOIComponentData, OOIComponentLoader, OOISiteConfig, OOISsrManager } from './types'
import { reportError } from '@wix/thunderbolt-commons'
import LazySentry from './lazySentry'
import { createTpaWidgetNative } from './tpaWidgetNativeFactory'

export const ooiComponentsRegistrar = withDependencies(
	[named(SiteFeatureConfigSymbol, name), ReactLoaderForOOISymbol, optional(OOISsrManagerSymbol)],
	(
		{ ooiComponentsData }: OOISiteConfig,
		loader: OOIComponentLoader,
		ooiSsrManager?: OOISsrManager
	): IComponentsRegistrar => {
		const loadComponent = ({ componentUrl, sentryDsn }: OOIComponentData) => async () => {
			try {
				const ReactComponent = await loader.loadComponent(componentUrl)
				if (!ReactComponent) {
					reportError(new Error('component is not exported'), LazySentry, sentryDsn)
				}
				return { component: createTpaWidgetNative({ ReactComponent, ooiSsrManager }) }
			} catch (error) {
				reportError(error, LazySentry, sentryDsn)
				return { component: createTpaWidgetNative({ ooiSsrManager }) }
			}
		}
		return {
			registerComponents: (hostAPI) => {
				Object.entries(ooiComponentsData).forEach(([widgetId, { componentUrl, sentryDsn }]) => {
					hostAPI.registerComponent('tpaWidgetNative', loadComponent({ componentUrl, sentryDsn }), widgetId)
				})
			},
		}
	}
)
